@import 'src/assets/variables';

.blog-highlight-card {
  background: $secondary-background-color;
  color: $primary-color;
  margin: 2rem 1rem;
  width: 90vw;
  text-align: center;
  break-inside: avoid;

  img {
    width: 100%;
    height: 14rem;
    object-fit: cover;
    object-position: top;
  }

  .blog-highlight-card--title {
    padding: 0 1rem;
    font-weight: bold;
    font-size: .9rem;
  }

  .blog-highlight-card--content {
    padding: 0 1rem;
    height: 5rem;
    font-size: .8rem;

  }

  .blog-highlight-card--footer {
    text-transform: uppercase;
    font-family: $heading-font;
    font-size: .8rem;
    font-weight: bold;
    padding-bottom: 1rem;
  }

  @media (min-width: $large-size) {
    width: 20rem;
    height: 27rem;
  }
}
