@import "src/assets/variables";

.section-neighborhood {
  .neighborhood-slider {
    width: 100%;
    overflow: hidden;
  }

  .neighborhood-slider-container {
    display: flex;
  }


  .neighborhood-slider-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    transition: all 0.7s;
  }

  .icons {
    display: flex;
    justify-content: space-evenly;
    background: $primary-color;

    .icon {
      width: 11vw;
      background: $primary-color;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 8vw;
        object-fit: contain;
        @media (min-width: $large-size) {
          width: 6vw;
        }
      }
    }

    .icon-light {
      background: $tertiary-background-color;
    }

    .icon:hover {
      opacity: .8;

    }
  }

  .neighborhood {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;

    iframe {
      width: 100vw;
      height: 20rem;
    }

    .right {
      width: 100vw;
      max-height: 30rem;
      background-size: cover;
      background-position: center;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: space-around;
    }

    @media (min-width: $large-size) {
      flex-direction: unset;
      iframe {
        width: 50vw;
        height: 30rem;
      }

      .right {
        width: 50vw;
        height: 35rem;

      }
    }

    .neighborhood-card {
      background: rgba(234, 233, 227, .9);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
      margin: 2rem;
      width: 80vw;
      @media (min-width: $large-size) {
        max-width: 40vw;
      }

      header {
        img {
          width: 60vw;
          max-height: 8rem;
          object-fit: contain;
          margin: 1rem;
          @media (min-width: $large-size) {
            width: 30vw;
          }
        }
      }

      p {
        text-align: left;
        margin: 1rem;

      }

      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column-reverse;
        margin: 2rem;
        @media (min-width: $large-size) {
          flex-direction: unset;
        }

        .muncher-button {
          padding: 1rem 4rem;

          a {
            color: $primary-background-color;
          }

          a:hover {
            opacity: 1;
          }

        }

        .metadata-items {
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            white-space: nowrap;
          }
        }

        .metadata {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 2.5rem;
            @media (min-width: $large-size) {
              width: 3rem;
            }
          }
        }
      }
    }

  }
}