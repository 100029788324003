  @import 'src/assets/variables';

.section-blog--page {

  .blog-page--heading {
    padding: 0 1rem;
    text-transform: none;
  }
  @media (min-width: $medium-size) {
    .blog-page--heading {
      text-align: left;
      padding: 0 3rem;
    }

  }
}