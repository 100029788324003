@import "src/assets/variables";
.home-card {
  text-align: center;
  width: 100vw;
  @media(min-width: $medium-size) {
    width: 15rem;
  }

  img {
    width: 6rem;
  }

  header {
    font-size: 1.2rem;
  }

  p {
    font-size: .8rem;
    text-align: center;
    @media(min-width: $medium-size) {
      text-align: justify;
    }

  }

  ul {
    column-count: 1;
    padding: 0;
    margin: 0;
    text-align: left;

    li {
      font-size: .8rem;
      padding: 0;
      margin: 0 2rem;
    }
  }
}