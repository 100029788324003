@import "src/assets/variables";

.section-about {
  padding-bottom: 0;
  .div-about {
    .left, .right {
      width: 100vw;
      height: 23rem;
    }
    @media(min-width: $medium-size) {
      display: flex;
      align-items: center;
      justify-content: center;
      .left, .right {
        width: 50%;
        height: 23rem;
      }
    }

    .left {
      background-image: url("about-hero.jpg");
      background-size: cover;
      background-position: top;
    }

    .right {
      background: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;

      .right-content {
        color: $primary-background-color;
        text-align: center;
        margin: .5rem;
        @media(min-width: $medium-size) {
          margin: 3rem;
          p{
            text-align: justify;
          }
        }


        header {
          font-size: 1.8rem;
          font-weight: bold;
          font-family: $sans-serif-font;
        }

        .muncher-button {
          padding: .8rem 3rem;
          margin: 1rem;
        }
      }


    }
  }
}