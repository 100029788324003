@import "src/assets/variables";

$maintenance-section-background-color: $primary-background-color !default;
.section-maintenance {
  font-size: 85%;
  padding-top: 2em;
  text-align: left;
  background: $maintenance-section-background-color;

  p {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  ul {
    column-count: 1;
    padding: .5rem 2rem;
    margin: .2rem;
  }

  .announcement {
    background: grey;
    padding: 2rem;

    .announcement-content {
      padding: .4rem;
      @media(min-width: $medium-size) {
        padding: 1rem;
      }
      background: $maintenance-section-background-color;
    }


    p {
      margin: 0 1rem;
    }
  }

  .maintenance-options {
    margin-left: 1rem;

    .maintenance-option {
      margin: 1rem;
    }
  }

  .maintenance-action {
    margin: 2rem 0;
  }
}

