@import "src/assets/variables";

.leasing-office-card {
  p {
    max-width: 90vw;
    font-size: .8rem;

  }

  .leasing-office-card--title {
    font-family: $heading-font;
    font-weight: bold;
  }

  .leasing-office-card--value {
    font-size: .7rem;
  }

  .leasing-office-card--link {
    text-decoration: underline;
  }

  .leasing-office-card--description {
    font-style: italic;
    font-weight: bold;
    font-size: .8rem;
    line-height: 1.2rem;
  }

  .leasing-office-card--emphasize {
    line-height: 1.2rem;
  }

  .leasing-office-card-two-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 60vw;

  }

  .leasing-office-card-map--image {
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      padding: 0;
    }

  }

  .leasing-office-card--image {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      padding: 0;
    }

  }
}