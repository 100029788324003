@import 'src/assets/variables';

.blog-content {
  font-size: .9rem;
  padding: 0 1rem 1rem 1rem;
  @media (min-width: $medium-size) {
    margin: 0 2rem 2rem 2rem;
    min-height: 20rem;
  }
  ul {
    column-count: 1;
  }
  img{
    height: auto;
    max-width: 80vw;
  }
  @media (min-width: $large-size) {
    img {
      max-width: 60vw;
    }
  }
}
