@import 'src/assets/variables';

.section-employments {
  .muncher-table {
    .row {
      grid-template-columns: minmax(10rem, 65fr) 35fr;
      @media(min-width: $medium-size) {
        grid-template-columns: minmax(10rem, 35fr) 20fr 15fr 15fr 15fr;
      }
    }
  }

}