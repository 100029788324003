@import 'src/assets/variables';

.blog-card {
  color: $primary-color;
  background: $secondary-background-color;
  page-break-inside: avoid; /* For Firefox. */
  -webkit-column-break-inside: avoid; /* For Chrome & friends. */
  break-inside: avoid; /* For standard browsers like IE. :-) */
  text-align: center;
  img {
    height: auto;
    width: 100%;
    object-fit: cover;
  }

  .blog-card--title {
    padding: .5rem 1rem 2rem 1rem;
  }

  @media (min-width: $large-size) {
    img {
      max-height: 25rem;
      max-width: 25rem;
    }
    text-align: left;
  }

}
