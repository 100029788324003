@import 'src/assets/variables';

.page-floorplans {
  margin-top: 2rem;

  .floorplan-card {
    a:hover {
      color: $secondary-background-color;
    }
  }
}
