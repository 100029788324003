@import "src/assets/variables";

.logo {
  img {
    height: 2.2rem;
    padding: 0.5rem;
  }

  @media (min-width: $medium-size) {
    img {
      height: 2.5rem;
      padding: .5rem 1rem;
    }
  }
}