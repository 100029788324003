@import "src/assets/variables";
.accessibility-main{
  .section-feature {
    background: $tertiary-background-color;

    i {
      font-family: $main-font;
      font-weight: 400;
      color: $primary-background-color;
    }

    text-align: center;
    @media (min-width: $extra-large-size) {
      .feature-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }
  .accessibility {
    i {
      font-family: $main-font;
      font-weight: 400;
    }
    .accessibility__feature {
      background: $tertiary-background-color;
      i {
        color: $primary-background-color;
      }
    }
    .accessibility__content {
      text-align: center;
      padding: 1rem;
      h2 {
        text-transform: none;
        color: $primary-color;
        letter-spacing: .01rem;
      }
      i{
        font-weight: 700;
        font-size: 1.2rem;
        color: $tertiary-background-color;
      }
      button {
        a {
          font-family: $main-font;
          text-transform: none;
          font-size: 1rem;
        }
      }
      @media (min-width: $medium-size) {
        padding: 1rem 3rem;
        hr {
          margin: 2rem;
        }
      }

    }

  }
}


