@import "src/assets/variables";

.div-contact--modal {
  position: relative;
  .close {
    position: fixed;
    top: 1rem;
    right: 1rem;
    padding: .4rem;
    color: $primary-color;
    cursor: pointer;
  }

  .close:hover {
    opacity: .6;
  }

  .muncher-modal {
    padding: 1rem 1rem 5rem 1rem;
    left: 0;
    top: 0;
    width: 100vw;
    overflow: scroll;
    max-height: 100vh;

    .section__contact .form--element {
      width: 70vw;
      @media (min-width: $medium-size) {
        max-width: 17rem;
      }
      @media (min-width: $large-size) {
        max-width: 25rem;
      }
    }

  }
}