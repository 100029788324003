@import 'src/assets/variables';

.section-property {
  padding: 0;

  .property-nav {
    background: #f0f0f0;
    margin-top: -.5rem;

    .container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      h5 {
        padding: .5rem;
        @media (min-width: $large-size) {
          padding: 1rem;
        }
      }
    }
  }

  .amenities-section {
    ul {
      @media (min-width: $extra-large-size) {
        column-count: 3;
      }
    }
  }


  .lease-office, .property-location {
    p {
      font-size: .8rem;
    }
  }

  .location-section, .amenities-section {
    h2 {
      margin-top: 4rem;
    }

  }

  .section-team, .section-pricing {
    margin: 2rem 0;

    .emphasized {
      text-transform: uppercase;
    }

  }
}
