@import "src/assets/variables";

.spotlight {
  width: 100%;
  background: $tertiary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;

  .left, .right {
    width: 100vw;
  }

  @media(min-width: $medium-size) {
    flex-direction: unset;
    .left, .right {
      width: 50%;
    }
  }

  .unit-spotlight {
    margin: 1rem;
    text-align: center;


    @media(min-width: $medium-size) {
      text-align: left;
    }
  }

  .left {
    header {
      font-size: 1.2rem;
      font-weight: normal;
    }

    p {
      font-size: .8rem;
      font-weight: bold;
      font-family: $sans-serif-font;
      color: $secondary-color;
    }
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    .div-spotlight-image {
      position: relative;

      .muncher-spinner {
        position: absolute;
        left: 50%;
        top: 6rem;
      }

      .spotlight-image {
        width: 100%;
        height: 16rem;
        object-fit: cover;
      }
    }
  }

  .unit-metadata {
    column-count: 2;

    .metadata {
      display: flex;
      align-items: center;

      p {
        color: $disabled-color;
        font-weight: normal;
      }

      img {
        width: 3rem;
      }

      .value {
        padding: 0 .4rem;
        color: $secondary-color;
        font-weight: bold;
        white-space: nowrap;
      }
    }
  }
  .muncher-button{
    margin: 1rem 0;
  }
}