@import 'src/assets/variables';

.blog-search-bar {
  color: $primary-color;
  padding: 2rem 1rem;
  text-align: center;

  .blog-search-bar--element {
    display: flex;
    align-items: center;
    justify-content: center;
    > *{
      margin: .5rem;
    }

  }

  @media (min-width: $large-size) {
    .blog-search-bar--element {
      input[type=text] {
        width: 40rem;

      }
    }
  }
}
