@import "src/assets/variables";

.section-hero-team {
  text-align: center;
  padding: 0;
  margin-bottom: -.2rem;

  .hero-img {
    width: 100vw;
    object-fit: cover;
    object-position: center;
  }
}