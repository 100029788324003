$primary-color: #40606f !default;
$secondary-color: #4b4f54 !default;
$primary-background-color: white !default;
$secondary-background-color: #eae9e3 !default;
$tertiary-background-color: #91a9b3 !default;
$tertiary-color: #f7f7f7 !default;

$nav-color: $primary-color !default;
$link-color: $primary-color !default;
$link-hover-color: $secondary-color !default;
$nav-background-color: $secondary-background-color !default;
$disabled-color: #959698 !default;
$danger-color: #a52a2a !default;
$success-color: green !default;
$border-color: #d8d8d8 !default;
$sans-serif-font: 'Merriweather Sans', sans-serif !default;
$monospace-font: 'Roboto Mono', monospace !default;
$serif-font: 'Merriweather', serif !default;
$button-font: $sans-serif-font !default;
$feature-section-background-color: $tertiary-color;
$slider-thumb-color: $primary-color !default;
@import "~@renaissancerentals/renaissance-component/lib/assets/variables";
