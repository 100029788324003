@import 'src/assets/variables';

.section-blogs-page {
  .blogs-page--footer {
    margin-bottom: 2rem;
  }

  @media (min-width: $medium-size) {
    h4 {
      text-align: left;
    }
    .blogs-page--footer {
      text-align: right;
    }

  }
}
