@import 'src/assets/variables';

@media print {
  @page {
    margin: 2rem 0;
  }

  .section-team {
    margin: 0;
    padding: 0;

    .members {
      display: table;
      margin: .5rem;

      .team-card {
        display: inline-block;

        img {
          height: 8rem;
          width: 8rem;
        }

        margin: 1.5rem;

        h4, p {
          width: 13rem;
          padding-bottom: .2rem;
        }
      }
    }

  }

  header {
    display: none;
  }
  .team-main {
    .section-hero-team {
      display: none;
    }

    .section-feature {
      display: none;
    }

    .section-team-gallery {
      display: none;
    }

    .div-align--center {
      display: none;
    }

    .container {
      display: none;
    }

    .section-blog-highlight {
      display: none;
    }
  }
  footer {
    display: none;
  }

}
.team-main {
  .section-feature {
    background: $tertiary-background-color;

    i {
      font-family: $main-font;
      font-weight: 400;
      color: $primary-background-color;
    }

    text-align: center;
    @media (min-width: $extra-large-size) {
      .feature-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }
}
