@import 'src/assets/variables';

.section-property--hero {
  padding: 0;
  margin: 0;
  position: relative;

  .property-hero--image {
    width: 100vw;
    height: 20rem;
    cursor: pointer;
    @media (min-width: $large-size) {
      height: 40rem;
    }
    object-fit: cover;
    object-position: center;
  }

  .hero-image-info {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    //background-color: rgba(75, 93, 66, 0.6);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    .hero-image--content {
      padding: 1rem;
      background-color: white;

      .property-hero--icon {
        height: 5rem;

      }
    }
  }
}
