@import 'src/assets/variables';
@import "@renaissancerentals/renaissance-component/lib/assets/form";

.section-utility-confirmation {
  .div-utility-confirmation {
    padding: 2rem;

    form {
      column-count: 1;
    }

    @media (min-width: $medium-size) {
      padding: 2rem 5rem;
    }
    @media (min-width: $large-size) {
      padding: 2rem 10rem;
    }
    @media (min-width: $extra-large-size) {
      padding: 2rem 15rem;
    }
    text-align: center;

    .content--item {
      text-align: left;
      padding: .5rem;
      cursor: pointer;
    }

    .content--item:hover {
      opacity: .6;
    }

  }

  .secondary {

    text-align: left;

    p {
      margin-bottom: 0;
    }

    ul {
      column-count: 1;
      padding: 0 1rem;
      margin: 0;

      a {
        text-decoration: underline;
      }
    }
  }

  .form-element {
    text-align: left;
    padding: 1rem 0;
  }

  .form-submit {
    text-align: center;
  }

  @media (min-width: $medium-size) {
    .form-submit {
      text-align: left;
    }
  }


  .muncher-input--div {
    .muncher-checkbox {
      white-space: normal;

      input {
        margin-right: .5rem;
      }
    }
  }

}