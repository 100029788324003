@import "src/assets/variables";

.section-action {
  text-align: center;
  padding: 1rem;

  .action-content {
    a {
      //color: $secondary-color;
    }

    .btn {
      white-space: normal;
    }
  }

  .floorplan-card {

    .featured {
      color: $primary-background-color;

      a {
        color: $primary-background-color;
      }
    }
    .floorplan-card-footer{
      background-color: rgba(0, 0, 0, 0.4);
    }

  }

  h2 {
    font-size: 1.2rem;
  }

  .muncher-icon {
    color: $primary-background-color;
  }
}

@media (min-width: $medium-size) {
  .section-action {
    .container {
      display: flex;
      text-align: left;
      align-items: center;
      justify-content: space-between;

      .action {
        display: flex;
        align-items: center;

        .action-content {
          padding: 0 1.5rem;

          h2 {
            text-align: left;
            margin-bottom: 0;
          }

          .btn {
            margin-left: 3rem;
            font-size: 1rem;

          }
        }
      }
    }
  }
}