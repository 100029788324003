@import 'src/assets/variables';

.section-employment {
  color: $secondary-color;
padding: 1rem;
  h4 {
    text-align: left;
    margin: 2rem 0;
  }

  ul {
    column-count: 1;
  }

}