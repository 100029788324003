@import "src/assets/variables";

.section-spotlight {
  .spotlights {
    position: relative;


    .spotlight-left, .spotlight-right {
      position: absolute;
      z-index: 100;
      top: 20%;

      .muncher-button {
        color: $secondary-background-color;
      }

    }

    .spotlight-right {
      right: 0;
    }

    @media(min-width: $medium-size) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .spotlight-left, .spotlight-right {
        position: relative;
        top: inherit;

        .muncher-button {
          color: $primary-color;
        }

      }
      .spotlight-right {
        right: inherit;
      }
    }
    .slider {
      width: 100%;
      overflow: hidden;
    }

    .slider-container {
      display: flex;
    }


    .slider-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      transition: all 0.7s;
    }
  }
}