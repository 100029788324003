@import "src/assets/variables";

.section-hero-accessibility {
  text-align: center;
  padding: 0;
  margin-bottom: -.2rem;

  .hero-img {
    width: 100vw;
    object-fit: cover;
    object-position: center;
  }

  .hero-left {
    position: relative;

    .img--hero {
      width: 100vw;
      object-fit: cover;
      object-position: center;
    }

    video {
      position: relative;
      width: 100vw;
    }
  }

  .hero-right {
    padding: 1rem;

    h1 {
      //line-height: 2rem;
      font-family: $main-font;
      text-transform: none;
      font-weight: 400;
      color: $primary-color;
    }
  }

  @media (min-width: $large-size) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 90%;
    .hero-left {
      width: 50vw;
      max-height: 30rem;

      .img--hero {
        max-height: 28rem;
        width: 50vw;
      }

      video {
        width: 50vw;
        object-fit: cover;
      }
    }
    .hero-right {
      width: 50vw;
    }
    .hero-content {
      padding: 0 0rem;

      p {
        text-align: left;
      }
    }
  }
  @media (min-width: $large-size) {
    font-size: 100%;
    .hero-content {
      padding: 0 1rem;
    }
    .hero-left {
      max-height: 35rem;

      .img--hero {
        max-height: 34rem;
      }
    }
  }
  @media (min-width: $extra-large-size) {
    .hero-content {
      // padding: 0 5rem;
    }
  }
}