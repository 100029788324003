@import "~@renaissancerentals/renaissance-component/lib/assets/App";
@import "variables";
.div-align--center {
  text-align: center;
  padding-bottom: 3rem;
}
main {
  .heading {
    border: none;
  }
}
.header-info {
  p {
    color: $primary-color;
  }

}