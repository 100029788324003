@import "src/assets/variables";

.div-home {
  .section-feature {
    .container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;

    }
  }
}