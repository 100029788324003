@import 'src/assets/variables';

.section-blog-highlight {
  background: $primary-color;
  color: $primary-background-color;

  .heading {
    text-transform: none;
  }

  .div-highlight--blogs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}
