@import 'src/assets/variables';

.section-blogs {
  .div-blogs{
    -webkit-column-count: 1;
    column-count: 1;
    -moz-column-count: 1;
    -webkit-column-gap: 2rem;
    -moz-column-gap: 2rem;
    column-gap: 2rem;
    @media (min-width: $medium-size) {
      -webkit-column-count: 2;
      column-count: 2;
      -moz-column-count: 2;
      -webkit-column-gap: 1rem;
      -moz-column-gap: 1rem;
      column-gap: 1rem;
    }
    @media (min-width: $large-size) {
      -webkit-column-count: 3;
      column-count: 3;
      -moz-column-count: 3;
    }
  }
}
